.App {
  text-align: center;
  /* overflow: none; */
}
#all-container {
  overflow: hidden;
  display: flex;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#map {
  position: sticky;
  height: 96vh;  
}

#options-container {
  position: sticky;
  top: 450px;
  left: 1300px;
  z-index: 1001;
  width: 430px;
  height: 40px;
  background-color:  rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  font-size: small;
  font-weight: bold;
  text-align: center;
}

/* #options {
  font-size: larger;
  font-weight: bold;
} */
h2 {
  margin: .5em !important;
  text-align: center;
}

.label-container { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 40%;
  left: 60%;
  z-index: 1000;
  width: 16%;
  height: 45vh;
  background-color: rgba(255,255,255, 0.5);  
  /* padding: 5px;     */
  border-radius: 5px; 
}

.label-title {
  position: absolute;
  top: 10px;
  z-index: 450;  
  text-align: center;
  padding-bottom: 5px;
  font-weight: bold;
  
}
li.label-entry {
  list-style-type: none;
  font-size: 1.5em;
  color: black;
  font-weight: 450;
  padding: .2em;
}
.cat-color {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 5px;
}

/* Style the main map container */
.leaflet-container {
  position: sticky;
  /* height: 100%; */
  /* height: 96vh; */
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 auto;  
}


/* Style the map canvas */
.leaflet-map-pane {
  background-color: #f0f0f0;
}


/* Style marker icons */
.leaflet-marker-pane img {
  width: 32px;
  height: 32px;
}


/* Style popups */
/* .leaflet-popup-pane {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
} */


/* Style Leaflet controls */
.leaflet-control {
  background-color: transparent;
}


/* Style toolbar-like elements */
.leaflet-bar {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.logo-container {
  text-align: center;
  padding: 2px;
}

.tilogo{
  height: 40px;
  width: auto;
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.label-entries {
  /* font-size: 1.5rem !important; */
  color: black;
  /* padding: .5rem !important; */
  margin: .5rem !important;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
